import React, { Component }  from 'react';
/*import MyForm2 from './MyForm2 .js';*/
import JCpic from '/var/www/html/A_Held/client/src/JC.jpg'
import Socials from './Socials.js';
export default function Gallery() {
    return (

      <div>
        <h1>Team</h1>
        <section className="profile">
          <h2>Juliusz Cydzik</h2>
          <img
            className="avatar"
	    src={JCpic}
            alt="Juliusz Cydzik"
            width={70}
            height={70}
          />
          <ul>
            <li>
              <b>Profession: </b>
              CEO and Owner
            </li>
          </ul>
        </section>
        <div></div>
        <div></div>
        <div></div>
        <div>
          <ul>
          <h1>Contact</h1>
      contact@heldsolutions.com
      <Socials/>
      </ul>
    </div>
    </div>
   
  );
}
//https://react.dev/learn/passing-props-to-a-component//
