import ReactPlayer from 'react-player';
import React, { Suspense, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import HeldTab from './HeldTab.js';
import CloudTab from './CloudTab.js';
import Card from 'react-bootstrap/Card';
function OpportunitiesTab() {
  return (
    <>
    <Card border="info">
    <Card.Header></Card.Header>
    <Card.Body>
      <Card.Title>  </Card.Title>
      <Card.Text>
      <CloudTab/>
      </Card.Text>
    </Card.Body>
  </Card>
 <br/>
          <Card border="success">
            <Card.Header></Card.Header>
            <Card.Body>
              <Card.Title>  </Card.Title>
              <Card.Text>
              <HeldTab/>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />
          <Card border="danger">
          <Card.Header>Casino.AI</Card.Header>
          <Card.Body>
            <Card.Title>Casinos,AI,Data Science, Neuropsychology</Card.Title>
            <Card.Text>
    
             Organization which  studies  gambling behaviors, neuroscience behind gambling, and the casino as a business entity through the use of data science, In order to increase taxable revenue for the business and the consumer. </Card.Text>
          </Card.Body>
        </Card>
        <br />
    
    
        </>
      );
    }
 export default OpportunitiesTab;   
