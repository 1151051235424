import React, { Component }  from 'react';
import "./HeldTab.css";
export default function HeldTab() {
  return (

        <>
        <div className='Title'>
   <h1> HELD SPACES     </h1>
        <ul/>
        </div>
  <p className="Held">
   Held Spaces sees opportunity where others dont, by bringing new life to spaces in all kinds of places.
  </p>
          <div className="mission">
           <p> Multi use spaces that have science embedded into their design or help facilitate further scientific discovery  focusing on:</p>
<p>Leisure </p>


<p>Science+ Engineering </p>

<p>Storage</p>

<p>Hospitality</p>

<p>Conferences
          </p>
          </div>
          </>

  );
}
