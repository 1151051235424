import React, { Suspense, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AboutTab from './AboutTab.js';
import TabButton from './TabButton.js';
import OpportunitiesTab from './OpportunitiesTab.js';
import './App.css';

    
    export default function TabContainer() {
      const [tab, setTab] = useState('about');
      return (<>
        <div className='centerTab'>
        <Suspense fallback={<h1>🌀  Loading...</h1>}>
          
          <TabButton
            isActive={tab === 'about'}
            onClick={() => setTab('about')}
          >
            About
          </TabButton>
          <TabButton
            isActive={tab === 'Opportunities'}
            onClick={() => setTab('Opportunities')}
          >
            Opportunities
          </TabButton>
        
          <hr />

          {tab === 'about' && <AboutTab />}
          {tab === 'Opportunities' && <OpportunitiesTab />}
          
        </Suspense>
        </div>
        </>
      );
    }

