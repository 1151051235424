import React, { Component }  from 'react';
import ReactPlayer from 'react-player'
import Gallery from './Gallery.js';
import Youtube from './Youtube.js';
import HeldLogo from '/var/www/html/A_Held/client/src/HeldSolutions-Blue.png'
import "./About.css";

export default function AboutTab() {
  return (
          <>
          <p className="HeldLogo">
           <img
	  src={HeldLogo}
      alt="logo" width="350" height="300"

      />
          </p>
          <ul/>
<p className="Statement">
Scientists and engineers taking ideas further!
Held Solutions Inc. was founded by a scientist and engineer, aiming to improve the organizational aspect of
research. Held Solutions is a think tank for ideas and dreams, any opportunity or idea can become something more and doesn't necessarily
Have to belong to Held Solutions.
</p>
        <div className="team">
          <Gallery />
        </div>
        <div className="media">
          <Youtube />
</div>
        </>
        );
      }
