/*import React, { Component }  from 'react';*/
import React, { useTransition } from 'react';
import Button from 'react-bootstrap/Button';

export default function TabButton({ children, isActive, onClick }) {
  const [isPending, startTransition] = useTransition();
  if (isActive) {
    return <b>{children}</b>
  }
  if (isPending) {
    return <b className="pending">{children}</b>;
  }
  return (
    < Button variant="outline-info" size="lg" onClick={() => {
      startTransition(() => {
        onClick();
      });
    }}>
      {children}
    </Button>
  );
}
