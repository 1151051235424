import {AnimatedSocialIcon} from 'react-animated-social-icons';
export default function Socials() {
    return (
        

        <div style={{display:"flex", flexDirection:"row"}}>
            <AnimatedSocialIcon brandName="linkedin" url="https://www.linkedin.com/company/100911171/admin/feed/posts/" style={{padding: "0.5em"}}/>
            <AnimatedSocialIcon brandName="youtube" url="https://www.youtube.com/channel/UCzkpFEsT5l00eD_rdefzgiQ" style={{padding: "0.5em"}}/>
        </div>
        
)};

//https://github.com/meko-deng/react-animated-social-icons?tab=readme-ov-file
